<template>
<section class="hero is-dark is-bold">
    <div class="hero-body">
        <p class="title">
            Intraday
        </p>
        <p class="subtitle">
            Bayes Capital Management
        </p>
    </div>
</section>

</template>

<script>

export default {
    name: 'vue-header',
    data() {
    },
    created() {
    },
    methods:{
    }
}
</script>

<style>
</style>