<template>
  <vue-header/>
  <funds-intraday/>
</template>
  <script>
  import FundsIntraday from "@/components/FundsIntraday";
  import VueHeader from "@/components/VueHeader";
  
  
  export default {
    components: {
      VueHeader,
      FundsIntraday,
    }
  };
  </script>
  
  <style>
      @import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
      @import "@/assets/css/styles.css";
  </style>