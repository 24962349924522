
import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import LoginPage from "@/components/LoginPage.vue";
import store from '@/store.js'


const routes = [
  {
    path: "/LoginPage",
    name: "LoginPage",
    component: LoginPage
  },
  {
    path: "/",
    name: "Home",
    component: HomePage,
    beforeEnter: (to, from, next) => {
      console.log(store.state.authenticated);
      if (store.state.authenticated == false) {
        console.log(store.state.authenticated);
        next("/LoginPage");
      } else {
        next();
      }
    }
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;