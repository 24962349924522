<template>
  <router-view />
</template>

<script>
</script>

<style>
    @import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
    @import "assets/css/styles.css";
</style>