<template>
    <div>
        <section>
            <div class="container py-4">
                <div class="box is-large">
                    <!-- <h1 class="p-2 cardHeader is-size-3">Intraday Perf.</h1> -->
                    <table class="table is-striped perfoTable">
                        <tbody>
                            <tr>
                                <td>LS M</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.LS.ret < 0 }">{{
                                        intradayPNL.LS.ret.toFixed(2)
                                }}%</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.LS.fin < 0 }">
                                    {{ intradayPNL.LS.fin.toLocaleString('pt-BR', moneyFmt).slice(0, -3) }}
                                </td>
                            </tr>
                            <tr>
                                <td>LB FIM</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.LB.ret < 0 }">{{
                                        intradayPNL.LB.ret.toFixed(2)
                                }}%</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.LB.fin < 0 }">
                                    {{ intradayPNL.LB.fin.toLocaleString('pt-BR', moneyFmt).slice(0, -3) }}
                                </td>
                            </tr>
                            <tr>
                                <td>LO FIA</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.LO.ret < 0 }">{{
                                        intradayPNL.LO.ret.toFixed(2)
                                }}%</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.LO.fin < 0 }">
                                    {{ intradayPNL.LO.fin.toLocaleString('pt-BR', moneyFmt).slice(0, -3) }}
                                </td>
                            </tr>
                            <tr>
                                <td>IBOV</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.ibov.ret < 0 }">{{
                                        intradayPNL.ibov.ret.toFixed(2)
                                }}%</td>
                                <td v-bind:class="{ 'has-text-danger-dark': intradayPNL.ibov.ret < 0 }">
                                    {{ intradayPNL.ibov.fin.toLocaleString('pt-BR', moneyFmt).slice(3, -3) }} pts
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h4 class="has-text-right has-text-weight-light">{{ intradayPNL.datetime }}
                    </h4>

                </div>
                <div class="container">
                    <button class="button is-medium" v-on:click="this.loadRetFile();">Atualizar</button>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    name: "funds-intraday",
    data() {
        return {
            intradayPNL: {
                LO: { ret: undefined, fin: undefined },
                LS: { ret: undefined, fin: undefined },
                LB: { ret: undefined, fin: undefined },
                ibov: { ret: undefined, fin: undefined },
                datetime: undefined,
            },
            preParse: [],
            moneyFmt: {
                style: 'currency',
                currency: 'BRL',
                // maximumFractionDigits: 0
            },
            timer: ''
        };
    },
    methods: {
        loadRetFile() {
            console.log('updating')
            // this.$papa.parse("data/mobileIntraday.csv" + "?_=" + (new Date).getTime(), {
            this.$papa.parse("https://mobile.bayescm.com/data/mobileIntraday.csv" + "?_=" + (new Date).getTime(), {
                header: true,
                skipEmptyLines: true,
                download: true,
                complete: (results) => {
                    // console.log("loaded");
                    this.preParse = results.data;
                    // console.log(results.data[0].Return);

                    this.intradayPNL.ibov.ret = parseFloat(results.data[0].Return)
                    this.intradayPNL.LS.ret = parseFloat(results.data[1].Return)
                    this.intradayPNL.LB.ret = parseFloat(results.data[2].Return)
                    this.intradayPNL.LO.ret = parseFloat(results.data[3].Return)

                    this.intradayPNL.ibov.fin = parseFloat(results.data[0].Value)
                    this.intradayPNL.LS.fin = parseFloat(results.data[1].Value)
                    this.intradayPNL.LB.fin = parseFloat(results.data[2].Value)
                    this.intradayPNL.LO.fin = parseFloat(results.data[3].Value)

                    this.intradayPNL.datetime = results.data[0].LastAtt

                }
            });
        },
    },
    created: function () {
        // console.log("created");
        this.loadRetFile();
        // wait(300);
    },
    mounted() {
        // console.log("mounted");
        // console.log(this.preParse);
    },
}
</script>
<style>

</style>
  