// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import VuePapaParse from 'vue-papa-parse'
import './registerServiceWorker'
import router from './router' 
import store from './store.js' 

const app = createApp(App);

app.use(store);
app.use(router);
// register plugins
app.use(VuePapaParse);
app.mount('#app')

// Now the app has started!