<template>
    <VueHeader/>
    <div class="container py-4">
        <input class="input" type="password" name="password" v-model="input.password" placeholder="Password" />
    </div>
    <div class="container">
        <button class="button" type="button" v-on:click="login()">Unlock</button>
    </div>
</template>

<script>
import VueHeader from "@/components/VueHeader";
export default {
    components: {
        VueHeader
    },
    name: "LoginPage",
    data() {
        return {
            input: {
                password: ""
            }
        }
    },
    methods: {
        login() {
            if (this.input.password == "billions") {
                this.$store.commit("setAuthentication", true);
                this.$router.replace({ name: "Home" });

            } else {
                console.log("The username and / or password is incorrect");
            }
            console.log(this.$store.state.authenticated);

        }
    }
}
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import "@/assets/css/styles.css";
</style>
