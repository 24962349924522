import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
    key: 'vuex', // The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
  })
const store = new Vuex.Store(
    {
        state: {
            authenticated: false
        },
        plugins: [vuexLocalStorage.plugin],
        mutations: {
            setAuthentication(state, status) {
                state.authenticated = status;
            }
        }
    }
);

export default store
